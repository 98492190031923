html, body {
    height: 100%;
}

.boldy {
    font-weight: 600;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.main-panel {
    min-width: 50%;
}

.toast-message {
    position: fixed;
    bottom: 30px;
    left: 15px;
    right: 15px;
    text-align: center;
    z-index: 1100;
    border: 0;
}

.toast-message > i {
    padding-right: 5px;
}

.slidein-right {
    animation: 0.5s slidein-right;
}

@keyframes slidein-right {
    from { left: -1000px; }
    to { left: 0px; }
}

.slideout-left {
    left: -1000px;
    animation: 0.5s slideout-left;
}

@keyframes slideout-left {
    from { left: 0px; }
    to { left: -1000px; }
}

.backdrop-in {
    opacity: 0.2;
    animation: 0.5s backdrop-in;
}

@keyframes backdrop-in {
    from { opacity: 0; }
    to { opacity: 0.2; }
}

.backdrop-out {
    opacity: 0;
    animation: 0.5s backdrop-out;
}

@keyframes backdrop-out {
    from { opacity: 0.2; }
    to { opacity: 0; }
}

/* Sizing according to screen size */

@media (min-width: 576px) {
    .fixed-w-sm-45 {
        min-width: 45%;
        max-width: 45%;
    }
    .min-w-sm-50 {
        min-width: 50%;
    }
    .min-w-sm-75 {
        min-width: 75%;
    }
    .w-sm-50 {
        max-width: 50%;
    }
    .w-sm-75 {
        max-width: 75%;
    }
}

@media (min-width: 768px) {
    .min-w-md-50 {
        min-width: 50%;
    }
    .min-w-md-75 {
        min-width: 75%;
    }
    .w-md-50 {
        max-width: 50%;
    }
    .w-md-75 {
        max-width: 75%;
    }
}

@media (min-width: 992px) {
    .min-w-lg-50 {
        min-width: 50%;
    }
    .min-w-lg-75 {
        min-width: 75%;
    }
    .w-lg-50 {
        max-width: 50%;
    }
    .w-lg-75 {
        max-width: 75%;
    }
}

@media (min-width: 1200px) {
    .min-w-xl-50 {
        min-width: 50%;
    }
    .min-w-xl-75 {
        min-width: 75%;
    }
    .w-xl-50 {
        max-width: 50%;
    }
    .w-xl-75 {
        max-width: 75%;
    }
}

/* Loader icon on top of anything */

div.loader-container {
    height: 0;
    width: 50px;
    overflow: visible;
    position: sticky;
    top: 12px;
    left: calc(50% - 25px);
}

div.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-bottom: 0.25px solid #6c757d;
    border-radius: 25px;
    background-color: white;
    color: #3e5795;
}

/* Schema tabs */

nav.schema-tabs {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    overflow-x: auto;
    height: 45px;
    border-top: 1px solid #6c757d;
    border-bottom: 2px solid #9aadde;
}

nav.schema-tabs > button.tab {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 90px;
    border: 0px none;
    color: #f8f8f8;
    background-color: #5374c6;
}

nav.schema-tabs > button.tab:active {
    background-color: #3e5795;
}

nav.schema-tabs > button.tab.active {
    font-weight: bold;
    border-bottom: 6px solid #9aadde;
}

/* Rounded buttons with a single icon */
button.icon-button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.icon-button-small {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.icon-button-small > svg {
    font-size: 1rem;
}

/* 'Add new' button for lists */

div.add-new-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-bottom: 0.25px solid #6c757d;
    border-radius: 25px;
    background-color: white;
    position: absolute;
    bottom: 12px;
    right: 12px;
}

/* Refresh button for lists */

div.refresh-button {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

div.refresh-button > button {
    margin-top: 6px;
}

/* Schema lists */

div.schema-list-container {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
}

/* Thing items */

div.thing-item, div.thing-aggregate {
    padding-left: 1rem;
    padding-right: 1rem;
}

div.thing-item > button {
    background-color: #dae6ff;
    border-top: 0.5px solid #6c757d;
    border-radius: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

div.thing-item > button:active {
    background-color: #b9c4d9;
}

div.thing-item > button.first {
    border-top: 1.5px solid #6c757d;
}

div.thing-item > button.last {
    border-bottom: 1.5px solid #6c757d;
}

/* Thing aggregates */

div.thing-aggregate > p {
    font-size: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

div.thing-aggregate > p.first {
    border-top: 1.5px solid #6c757d;
}

div.thing-aggregate > p.last {
    border-bottom: 1.5px solid #6c757d;
    margin-bottom: 0px;
}

/* Displayed images */
.file-display {
    margin-top: 5px;
    text-decoration: none;
    color: #455868;
    display: block;
    text-align: center;
    font-size: 5rem;
}

.file-display.clickable {
    cursor: pointer;
}

.file-display > img {
    max-height: 280px;
    width: 100%;
    max-width: 900px;
    object-fit: cover;
    font-size: 2rem;
}

/* File items */

div.file-item > button {
    background-color: #dae6ff;
    border-radius: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

div.file-item > button.delete {
    justify-content: center;
    background-color: #f8f6ce;
}

div.file-item > button:active {
    background-color: #b9c4d9;
}

div.file-item > button.delete:active {
    background-color: #dcd40b;
}

/* ToggleSwitch
   Source: https://proto.io/freebies/onoff/ */

.onoffswitch {
    position: relative; width: 90px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "Sí";
    padding-left: 10px;
    background-color: #34A7C1; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "No";
    padding-right: 10px;
    background-color: #EEEEEE; color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 22px; height: 22px; margin: 6px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 56px;
    border: 2px solid #999999; border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

/* PluralField specifics */

.w-100-but-40-px {
  width: calc(100% - 40px) !important;
}

/* FileField specifics */

button.filefield-button {
    width: 40px;
}

/* RelationField specifics */

button.relation-field-selector {
    background-color: #dae6ff;
    border: 0;
    border-radius: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
